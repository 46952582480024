<template>
	<div class="dynamic-page">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4">
                    <span class="text-muted fw-light">
                        <a href="/">Bosh sahifa</a> <i class="mdi mdi-chevron-right"></i>
                    </span> Aloqa
                </h4>
            </div>
            <div class="contact">
                <h2 class="mb-3 text-center pt-3">Kontaktlar</h2>
                <div class="row mt-5 mb-5">
                    <div class="contact-card col-md-4">
                        <div class="contact-info">
                            <h3>Kontakt ma’lumotlari</h3>
                            <p class="pb-5">O‘zbekiston kino sanoati vakillari uyushmasi</p>
                            <ul class="pt-4">
                                <li>
                                    <div class="contact-icon">
                                        <i class="mdi mdi-phone-in-talk"></i>
                                    </div>
                                    <div class="contact-text">
                                        <span>+99871 2092002</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="contact-icon">
                                        <i class="ti-email"></i>
                                    </div>
                                    <div class="contact-text">
                                        <span>avtorfilmuz@gmail.com</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="contact-icon">
                                        <i class="ti-location-pin"></i>
                                    </div>
                                    <div class="contact-text">
                                        <span>100115, Toshkent shari, Chilonzor ko'chasi 1a-uy</span>
                                    </div>
                                </li>
                                <li class="pt-5">
                                    <div class="section-title-para">
                                        <p class="mb-3">Ijtimoiy tarmoqlar</p>
                                        <ul class="contact-social-bx">
                                            <li><a href="#" class="btn outline radius-xl"><i class="mdi mdi-facebook"></i></a></li>
                                            <li><a href="#" class="btn outline radius-xl"><i class="mdi mdi-twitter"></i></a></li>
                                            <li><a href="#" class="btn outline radius-xl"><i class="mdi mdi-instagram"></i></a></li>
                                            <li><a href="https://t.me/avtorfilmuz" class="btn outline radius-xl"><i class="mdi mdi-telegram"></i></a></li>
                                        </ul>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                                            <path d="M186.066 169.566V186.814H100.318C119.679 186.814 137.551 180.394 151.905 169.566H186.066Z" fill="#EFEFEF" fill-opacity="0.16"/>
                                            <path d="M100.318 15.3239C147.673 15.3239 186.066 53.7108 186.066 101.066C186.066 129.06 172.652 153.913 151.904 169.566C137.551 180.394 119.678 186.814 100.318 186.814C52.9632 186.814 14.5762 148.427 14.5762 101.066C14.5762 53.7108 52.9632 15.3239 100.318 15.3239ZM154.837 70.1831C154.837 59.6199 146.273 51.0622 135.709 51.0622C125.152 51.0622 116.588 59.6199 116.588 70.1831C116.588 80.7464 125.152 89.3104 135.709 89.3104C146.273 89.3104 154.837 80.7464 154.837 70.1831ZM154.837 136.823C154.837 126.26 146.273 117.695 135.709 117.695C125.152 117.695 116.588 126.26 116.588 136.823C116.588 147.38 125.152 155.944 135.709 155.944C146.273 155.944 154.837 147.38 154.837 136.823ZM110.78 103.5C110.78 98.8709 107.022 95.1186 102.393 95.1186C97.7638 95.1186 94.0115 98.8709 94.0115 103.5C94.0115 108.135 97.7638 111.887 102.393 111.887C107.022 111.887 110.78 108.135 110.78 103.5ZM88.197 70.1831C88.197 59.6199 79.6392 51.0622 69.076 51.0622C58.5128 51.0622 49.955 59.6199 49.955 70.1831C49.955 80.7464 58.5128 89.3104 69.076 89.3104C79.6392 89.3104 88.197 80.7464 88.197 70.1831ZM88.197 136.823C88.197 126.26 79.6392 117.695 69.076 117.695C58.5128 117.695 49.955 126.26 49.955 136.823C49.955 147.38 58.5128 155.944 69.076 155.944C79.6392 155.944 88.197 147.38 88.197 136.823Z" fill="#EFEFEF" fill-opacity="0.16"/>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <iframe src="https://yandex.uz/map-widget/v1/-/CDSmY0i0" width="100%" height="100%" frameborder="1" allowfullscreen="true"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>